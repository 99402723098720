function App() {
  return (
    <div className="App">
      <h1>My name is Rodion Khahush.</h1>
      <p>
        I'm a full stack developer.
        <p>I have over four years of experience</p>
      </p>
      <p>
        <span className='title'>Here's where you can find me on the internet:</span>
        <div>
          <a href="https://www.linkedin.com/in/rodion-khahush">
            Linkidin
          </a>
        </div>
        <div>
          <a href="https://vk.com/id838907316">vk.com</a>
        </div>
        <div>telegram : @RodionDev</div>
      </p>
    </div>
  );
}

export default App;
